.talkUploadClose {
  text-align: right;
  font-size: 1.5rem;
  font-weight: bold;
}

.talkUploadCloseButton {
  cursor: pointer;
}

.talkUploadTitle {
  padding-bottom: 1rem;
  text-align: center;
  font-size: 1.25rem;
}

.talkUploadSelectButtons {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* max-width: 10rem; */
}

.talkUploadSelectFileTitle {
  padding-bottom: 1rem;
  font-size: 1.25rem;
}

.talkUploadAboutFile {
  padding-top: 0.5rem;
  font-size: 0.9rem;
}

.talkUploadPreview {
  height: 100px;
}

.talkUploadSelectClose {
  padding: 1rem 0;
}

.talkUploadGoback {
  padding: 1rem 0;
  font-size: 0.75rem;
}

.talkUploadTextInput {
  padding: 1rem 0;
}

.talkUploadActionButtons {
  padding-top: 1rem;
  display: flex;
}

.talkUploadError {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .talkUploadSelectButtons {
    flex-direction: row;
  }
}