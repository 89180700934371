.buttonSmall {
  /* text-align: right; */
  font-size: 0.75rem;
  font-weight: bolder;
  margin-top: 1rem;
}

.buttonsContainer {
  display: flex;
  font-size: 0.75rem;
  font-weight: bolder;
  margin-top: 1rem;
}

.confirmMessage {
  font-size: 1rem;
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.resultMessage {
  font-size: 1rem;
  font-weight: bolder;
  margin-top: 1rem;
}

.grupInfoMemberListImage {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 2px;
}
