.sepImageModal {
  position: fixed;
  width: 90%;
  /* max-width: 70%; */
  /* height: 50%; */
  height: 90%;
  /* max-height:80%; */
  overflow: auto;
  left: 5%;
  top: 5%;
  background: var(--background-color);
  border: 0.5px solid rgb(110, 110, 110);
  border-radius: 5px;
  z-index: 200;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.sepImageModalClose {
  text-align: right;
}

.sepImageModalCloseButton {
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

.sepImageModalImageContainer {
  text-align: center;
}

.sepImageModalImage {
  max-width: 100%;
  max-height: 75vh;
}

.textTalkFile {
  max-width: 100%;
}

.textTalkFileImage {
  max-width: 100%;
  max-height: 200px;
}

.textTalkFileVideo {
  /* width: 200px; */
  max-width: 100%;
  max-height: 200px;
}
/* @media (min-width: 768px) {
  .sepImageModal {
    width: 28rem;
  }
} */