.groupTalkTextItmeName {
  font-size: 0.8rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
}

.groupTalkTextItmeNameElement {
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.9rem;
  padding-top: 0.5rem;
}

.groupTalkTextItmeDestUserImage {
  height: 1.5rem;
  width: 1.5rem;
  object-fit: cover;
  border-radius: 2px;
}

.groupTalkTextItmeTimeElement {
  margin-left: 0.25rem;
  font-size: 0.9rem;
}

.groupTalkTextItemReactionButtonsContainer {
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.groupTalkTextItemReactionButton {
  cursor: pointer;
  color: transparent; 
  text-shadow: 0 0 0 var(--color);
}

.groupTalkTextItemSettingButtonContainer {
  font-weight: bolder;
  text-align: right; 
  margin-top: 1rem;
  /* text-shadow: 0 0 0 var(--color); */
}

.groupTalkTextItemSettingButton {
  cursor: pointer;
  width: 2rem;
}

.groupTalkTextItemSettingSeparator {
  border-bottom: 1px solid gray;
  margin-top: 0.5rem,
}

.groupTalkTextItemTextDeleteButton {
  cursor: pointer;
  margin-top: 1rem;
}
