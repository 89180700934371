.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 30rem;
  max-width: 80%;
  /* background: white; */
  background: var(--background-color);
  border: 0.5px solid #999;
  box-shadow: 1px 0 8px rbga(0, 0, 0, 0.26);
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  z-index: 200;
  /* padding: 3rem 2rem; */
  padding: 1rem 1rem;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-nav.open {
  transform: translateX(0);
}

.mobile-nav__items {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-nav__items.mobile {
    flex-direction: column;
}

.name {
  color: white;
  margin-left: 1rem;
}

.userImage {
  margin-top: 0.25rem;
  margin-left: 1rem;
  border-radius: 0.1rem;
}

.mobile-nav__userImage {
  margin-left: 1rem;
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 2px;
}

.mobile-nav__footer {
  /* position: absolute;
  top: 90% */
}

.mobile-nav__userImageContainer {
  display: flex;
  align-items: center;
}

.mobile-nav__name {
  color: var(--color);
  margin-top: 0.25rem;
  margin-left: 1rem;
}

.mobile-nav__separator {
  width: 100%;
  height: 0.5px;
  color: var(--color);
  background-color: var(--color);
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}