.main-nav {
  height: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1;
}

.main-nav__items {
  list-style: none;
  padding: 0;
  margin: 0 1.5rem;
  display: none;
}

.main-nav__user {
  display: none
}

.main-nav__userImage {
  margin-left: 1rem;
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 2px;
}

.name {
  color: white;
  margin-left: 1rem;
  cursor: pointer;
}

.userImage {
  margin-top: 0.25rem;
  margin-left: 1rem;
  border-radius: 0.1rem;
  cursor: pointer;
}

.createButton {
  font-size: 2rem;
  margin-left: 1rem;
  color: white;
  cursor: pointer;
}

.main-nav__logo {
  display: none;
}

.main-nav__authLink {
  margin-left: 1rem;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.main-nav__notify {
  position: relative;
  font-size: 1.5rem;
  cursor: pointer;
}

.main-nav__notifyNum {
  color: white;
  background: red;
  /* color: var(--color);
  background: var(--background-color); */
  position: absolute;
  right: -0.2rem;
  top: -0.3rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  padding: 0.2rem;
}

@media (min-width: 768px) {
  .main-nav__items {
    display: flex;
  }

  .main-nav__user {
    display: block;
  }

  .main-nav__logo {
    display: block;
  }
}
