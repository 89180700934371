.groupListNameContainer {
  display: flex;
  align-items: center;
}

.groupListName {
  margin-right: 0.25rem;
  cursor: pointer;
}

.groupListImage {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 2px;
  cursor: pointer;
}

.groupListMoreInfo {
  cursor: pointer;
}

.groupListCreatorImage {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
  border-radius: 2px;
}