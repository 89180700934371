.groupTalk__appContainer {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
}

.groupTalk__ConnectButton {
  position: fixed;
  top: 45px;
  right: 2%;
  font-size: 0.75rem;
  font-weight: bolder;
}

.groupTalk__InputLabel {
  margin-top: 2rem;
}

.groupTalk__BackToListButton {
  position: fixed;
  top: 115px;
  left: 2%;
}

.groupTalk__showGroupCreate {
  /* position: fixed;
  top: 170px;
  right: 1%;  */
  text-align: right;
}

.groupTalk__confirmCreateTitle {
  margin-top: 1rem;
  font-weight: bolder;
}

.groupTalk__confirmCreateInput {
  margin-top: 1rem;
  margin-left:0.5rem;
  word-break: break-all;
}

.groupTalk__buttonSmall {
  font-size: 0.75rem;
  font-weight: bolder;
}

.groupTalk__closeModalButtonContainer {
  /* text-align: right; */
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: bolder;
  margin-bottom: 0.25rem;
  /* cursor: pointer; */
}

.groupTalk__closeModalButton {
  cursor: pointer;
}

.groupTalk__showInputButton {
  position: fixed;
  /* width: 40%; */
  right: 5px;
  bottom: 0px;
  font-size: 0.75rem;
  font-weight: bolder;
}

.groupTalk__textInputContainer {
  position: fixed;
  bottom: 0px;
  /* height: 90px; */
  height: 130px;
  width: 100%;
}

.groupTalk__textInputElements {
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  flex-direction: column;
}

.groupTalk__textInputTextarea {
  /* width: 75%; */
  width: 90%;
}

.groupTalk__textInputPostButton {
  margin-top: 15px;
  margin-top: 0;
  /* width: 22.5%; */
  width: 75%; 
  font-size: 0.75rem;
  font-weight: bolder;

  display: flex;
  gap: 10px;
}

.groupTalk__createResult {
  margin-top: 1rem; 
  font-size: 1.25rem;
  font-weight: bolder;
}

.groupTalk__loader {
  /* font-size: 1rem; */
  text-align: center;
   margin-top: 1rem;
}


.groupList-listContainer {
  /* margin-top: 2rem; */
  padding: 1rem;
}

.groupList-groupName {
  font-size: 1.25rem;
  font-weight: bolder;
  /* cursor: pointer; */
}

.groupList-creatorContainer {
  display: flex; 
  align-items: center;
  gap: 0.25rem;
}

.groupList-creatorImage {
  margin-left: 0.5rem;
}

.groupList-listElement {
  margin-top: 1rem;
  /* cursor: pointer; */
  /* max-width: 30rem; */
}

.groupList-autoSuggestContainer {
  /* width: 65%; */
  width: 18rem;
}

.groupTalkTextList-container {
  position: fixed;
  /* bottom: 90px; */
  bottom: 130px;
  top: 145px;
  left: 0px;
  right: 0px;
  padding: 0.2rem;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  overflow-y: auto;
  /* height: 60vh; */
  /* width:100%; */
  /* margin-left:5%; */
  background: var(--textTalk-textContainerBackground);
  /* background-color: ghostwhite */
}

.groupTalkTextList-groupInfo {
  position: fixed;
  /* margin-top: 5rem; */
  top: 5rem;
  left: 1rem;
  max-width: 80%;
  max-height: 80%;
  /* background: var(--background-color); */
  overflow: auto;
  /* box-shadow: 0 1px 5px rgba(0, 0, 0, 0.26); */
  /* z-index: 10; */
}

.groupTalkTextList-allMemberList {
  position: absolute; 
  z-index: 10;
  font-size: 1rem;
  background: var(--background-color);
  max-height: 50%;
  max-width: 75%;
  overflow: auto;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.26);
}

.groupTalkTextList-allMemberElement {
  list-style-type: none;
  padding: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.groupTalkTextList-memberListElement {
  margin-right: 0.5rem;
}

.groupTalkTextList__joinGroupButton {
  position: fixed;
  top: 115px;
  /* right: 1%; */
  right: 1px;
}

.groupTalkTextList-groupNameContainer {
  position: fixed;
  top: 60px
}

.groupTalkTextList-groupName {
  font-weight: bolder;
}

.groupTalkTextList-showMemberButton {
  margin-top: 0.1rem;
  cursor: pointer;
}

.groupTalkTextList-joinModalElement {
  margin-top: 1rem;
}

.groupTalkTextList-joinModalCreator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
  /* cursor: pointer; */
}

.groupTalkTextList-descriptionContainer {
  padding: 0.75rem;
}

.groupTalkTextList-descriptionDate {
  /* text-align: right; */
  margin-top: 0.5rem;
}

.groupTalkTextList-TalkPageButton {
  text-align: right;
  margin-top: 1rem;
  font-size: 0.75rem;
  font-weight: bolder;
}

@media (min-width: 768px) {
  .groupTalk__appContainer {
    margin-right: 300px;
    margin-left: 0px;
  }

  .groupTalk__ConnectButton {
    left: 25rem;
  }
  .groupTalkTextList-container {
    /* width: 35rem; */
    margin-right: 350px;
  }

  .groupTalkTextList__joinGroupButton {
    /* left: 25rem; */
    margin-right: 350px;
  }

  .groupTalk__showGroupCreate {
    /* position: fixed; */
    /* left: 25rem; */
    /* margin-right: 300px; */
    margin-right: 2rem;
  }

  .groupTalk__groupEditContainer {
    width: 35rem;
  }

  .groupTalk__textInputContainer {
    /* width: 35rem; */
  }

  .groupTalk__textInputElements {
    margin-right: 350px;
  }

  .groupTalk__showInputButton {
    /* left: 28rem; */
    margin-right: 350px;
  }
  /* .single-post {
    width: 40rem;
  }; */
  /* .textTalk-container {
    width: 35rem;
  };
  

  .remote-videoContainer {
    width: 35rem; 
    height: 60vh;
  }

  .remote-video {
    width: 33rem;
    height: 60vh;
  }

  .remote-video-small {
    left: 30rem;
  }

  .local-video {
    left: 30rem;
  }

  .textTalk-OnlineUser-Container {
    width: 35rem;
  }

  .textTalk__ConnectButton {
    left: 10rem;
  }

  .textTalk__UserTalkList-Container {
    width: 35rem;
  }
  .textTalk__UserTalkListButton {
    left: 25rem;
    top: 70px
  }

  .textTalk-InputElement {
    width: 28rem;
  }

  .textTalk-InputButton {
    left: 29rem;
  }

  .textTalk__UserTalkListSuggest {
    width: 28rem;
  }

  .textTalk__UserTalkListSuggest {
    width: 28rem;
  }

  .textTalk__UserTalkListNoSuggest {
    width: 28rem;
  } */

}