.bodyContainer {
  /* margin-top: 2rem; */
}

.adLink {
  text-decoration: none;
  color: var(--color);
}

.groupTalkRightElementLink {
  text-decoration: none;
  color: var(--color);

}

.groupTalkTopBarElementContainer {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: -35px; */
  margin-top: 0px;
  margin-bottom: 15px;
    /* width: 200px; */
}

.groupTalkTopBarElement {
  width: 300px;
  height: 100%;
  border: 0.2px solid gray;
}




.groupTalkRightElements {
  display: none;
}

.groupTalkRightElementLink {
  text-decoration: none;
  color: var(--color);
}






.rightAdsContainer {
  display: none;
}

.rightAdsItem {
  display: none;
}

.topAdElementContainer {
  /* text-decoration: none;
  color: var(--color); */

  height: 65px;
  /* display: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: -35px; */
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: left;
}

.inPostsAdElementContainer {
  text-align: left;
  display: block;
}

@media (min-width: 768px) {
  .groupTalkRightElements {
    display: block;
    /* position: fixed; */
    /* left: 34rem; */
    right: 5px;
    top: 75px;
    border: 0.2px solid gray;
    height: 300px;
    width: 300px;
  }


  .groupTalkTopBarElementContainer {
    display: none;
  }

  .groupTalkTopBarElement {
    display: none;
  }



  

  .rightAdsContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    /* left: 34rem; */
    right: 0px;
    top: 65px;
    /* border: 3px solid red; */
    /* height: 700px; */
    max-height: calc(100vh - 70px);
    width: 330px;
    overflow-y: auto;
  }

  .rightAdsItem {
    display: block;
    width: 305px;
    /* border: 3px solid green; */
  }

  .topAdElementContainer {
    display: none;
  }

}






/* from adnet-frontend keep sync original*/

.body300x65Container {
  width: 300px; 
  height: 65px;
  border: 0.5px solid gray; 
  display: flex; 
  justify-content: space-between;
  margin-bottom: 1rem;
}

.body300x65Text {
  width: 235px;
  height: 100%; 
  overflow:hidden; 
  /* padding:  */
}

.body300x65TextContents {
  display:flex; 
  flex-direction:column; 
  justify-content: space-between; 
  padding:5px 0 0 10px;
}

.body300x65TextTitle {
  font-size: 16px; 
  font-weight:bold;
  text-align:center;
}

.body300x65TextDescription {
  font-size: 12px; 
  font-weight: bold;
  padding-top: 2px;
}

.body300x65Image {
  width: 65px;
  height:65px;
  object-fit: cover;
}

.body300x300Container {
  width: 300px; 
  margin-bottom: 1rem;
}

.body300x300Image {
  width: 300px;
  height: 300px;
  /* border: 0.5px solid gray; */
  object-fit: cover;
}

.body300x300TextContainer {
  width: 300px;
  height: 60px;
  display: flex;
  flex-direction: column;
  /* border: 0.5px solid rgb(231, 231, 231); */
  border: 0.5px solid gray;
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  overflow: hidden;
  /* background-color: rgb(238, 238, 238); */
  /* justify-content: space-between; */
}

.body300x300TextTitle {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.body300x300TextDescription {
  font-size: 13px;
  font-weight: bold;
  padding-top: 4px;
}

.bodyInPostContainer {
  width: 100%;
  max-width: 40rem; 
  height: 150px; 
  border: 0.5px solid gray;
  border-radius: 6px;
  overflow: hidden;
  /* display: flex; */
}

.bodyInPostContents {
  display: flex;
}

.bodyInPostImageContainer {
  width: 150px;
  height: 150px;
  /* border: 0.5px solid gray; */
  object-fit: cover;
}

.bodyInPostTextContainer {
  flex-grow: 2;
}

.bodyInPostTextTitle {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 20px 0 10px 0;
}

.bodyInPostTextDescription {
  font-size: 16px;
  padding: 0 5px;
}

@media (min-width: 480px) {
  .bodyInPostTextTitle {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    padding: 20px 0 10px 0;
  }
  
  .bodyInPostTextDescription {
    font-size: 22px;
    padding: 0 20px;
  }
}