/* #blocks {
  line-height: 2.5rem;
}
.letter {
  display: inline-block;
  text-align: center;
  line-height: 2rem;
  font-size: 1.5rem;
  height: 2rem;
  width: 2rem;
  font-family: sans-serif;
  font-weight: bold;
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 0.25rem;
} */

.groupListShowMoreButton {
  text-align: center;
  cursor: pointer;
  margin-top: 2rem;
}