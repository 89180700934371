.livePostComment {
  max-height: 70vh;
  overflow: auto;
  margin: 0 1.5rem;
  padding: 0.25rem;
  border: 1px solid gray;
  border-radius: 4px;
}

@media (min-width: 768px) {
  .livePostComment {
    margin: 0 auto;
  }
}