.listElement {
  margin-top: 1rem;
  /* cursor: pointer */
}

.listElementContent {
  font-weight: bolder;
  margin-left: 1rem;
}

.smallButtons {
  /* text-align: right; */
  font-size: 0.75rem;
  font-weight: bolder;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  display: flex;
  overflow: auto;
}

.editResult {
  margin-top: 1rem;
  margin-bottom: 1rem; 
  font-size: 1.25rem;
  font-weight: bolder;
}

.userInfoContainer {
  display: flex;
  align-items: center;
}

.userInfoContent {
  font-weight: bolder;
}

.userImageContainer {
  width: 4.5rem;
  height: 3rem;
  text-align: center;
  /* margin-right: 1rem; */
}

.userImageElement {
  max-width: 100%;
  max-height: 100%;
}


.controlAcceptBody {
  padding-top: 2rem;
}