.listElement {
  margin-top: 1rem;
  /* cursor: pointer */
}

.listElementContent {
  font-weight: bolder;
}

.buttonSmall {
  text-align: right;
  font-size: 0.75rem;
  font-weight: bolder;
}

.groupInfoNameContainer {
  display: flex;
  align-items: center;
}

.groupInfoName {
  margin-right: 0.25rem;
}

.groupInfoImage {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 2px;
}

.groupCreatorImage {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 2px;
}