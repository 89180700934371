.deletePostImagesContainer {
  display: flex;
  flex-direction: column;
}

.deletePostImagesImageContainer {
  height: "100px";
  position: relative;
}

.deletePostImagesImageContainer img {
  height: 100%;
  cursor: pointer;
  margin-right: 10px;
}

.deletePostImagesImageContainer video {
  /* height: 100%; */
  cursor: pointer;
  margin-right: 10px;
}

.deletePostImagesImageContainer div {
  background-color: rgba(0,0,0,0.75);
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.deletePostImagesVideoMark {
  background-color: rgba(0,0,0,0.05);
  color: gray;
  position: absolute;
  /* top: 0; */
  right: 10px;
  font-size: 1.25rem;
}

.deletePostImagesConfirmContainer {
  margin-top: 1rem;
}

.deletePostImagesConfirmButtons {
  margin-top: 2rem;
}

.deletePostImagesAudioSelectButton {
  font-size: 1.25rem;
}

/* .feedImages__column {
  float: left;
  padding: 5px;
} */