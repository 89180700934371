.pageNotifyList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.pageNotifyListClose {
  text-align: right;
}

.pageNotifyListCloseButton {
  cursor: pointer;
  font-size: 1.5rem;
}


.pageNotifyItem {
  padding: 1rem;
  border-bottom: 0.1px solid gray;
}

.pageNotifyItemLink {
  text-decoration: none;
  color: inherit;
}

.pageNotifyUserTitle {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.pageNotifyUserImage {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  border-radius: 2px;
}