.postSelectContainer {
  margin: 1rem auto;
}

.postSelectModalClose {
  text-align: right;
}

.postSelectModalCloseButton {
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}