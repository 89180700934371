
.infoButton {
  /* position: fixed;
  top: 50px;
  right: 5%; */
  font-size: 1.5rem;
  text-align: right;
  font-weight: bold;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  /* cursor: pointer; */
}

.infoButtonMark {
  cursor: pointer;
}

@media (min-width: 768px) {
  .infoButton {
    /* position: fixed; */
    top: 50px;
    /* left: 31rem; */
    /* margin-right: 3rem; */
    margin-right: 60px;
  }
}