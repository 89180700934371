.notPageFound {
  padding: 1rem;
}

.notPageFound__container {
  text-align: center;
}

.notPageFound__textLink {
  /* margin-top: 1.5rem; */
  /* margin-bottom: 1.5rem; */
  margin: 1.5rem;
  font-size: 1.5rem;
}

.notPageFound__pageButton {
  margin-top: 1rem;
}

.notPageFound__linkButtonContainer {
  display: flex;
  justify-content: center;
}

.notPageFound__linkButton {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.notPageFound__Loader {
  text-align: center;
}

.notPageFound__Icon {
  height: 1.25rem;
  margin-right: 0.5rem;
}

.notPageFound__ButtonNameContainer {
  display: flex;
  align-items: center;
}