.comment__replyInput {
  margin-left: 2rem;
}

.comment__actions {
  /* padding: 1rem; */
  /* margin-top: 0rem; */
  margin-bottom: 1rem;
  /* font-weight: bolder; */
  text-align: right;
}

.comment__deleteConfirm {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bolder;
  /* text-align: right; */
}

.comment__deleteButtons {
  /* margin-top: 2rem; */
  margin-bottom: 3rem;
  text-align: right;
}

.comment__actionButtons {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 2rem; */
}

.comment__parentContent {
  margin-top: 2rem;
}

.comment__replyElement {
  margin-left: 2rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.comment__replyElementContent {
  margin-bottom: 1rem;
}

.comment__contentText {
  font-weight: bolder;
  /* float: right; */
}

.comment__loader {
  display: flex; 
  justify-content: center;
  /* float: right; */
}

.comment__showFullCommentButton {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: right;
  font-weight: normal;
}


@media (min-width: 768px) {
  .single-post {
    width: 40rem;
  }
}