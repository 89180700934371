.liveContainer {
  position: relative;
}

.someContent {
  position: absolute;
  border: 1px solid red;
  width: 300px;
  height: 65px;
  top: 2px;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  color: gray;
}

@media (min-width: 768px) {
  .liveContainer {
    /* margin: 0 auto;
    margin-right: 340px;
    max-width: 40rem; */
  }
  .someContent {
    /* display: none; */
  }
}