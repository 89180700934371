
.container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* horizontal */
  align-items: center;     /* vertical */
  width: 100%;
  min-height: 350px;
}

.result {
  /* margin-top: 100px; */
  margin-top: 2rem;
  word-wrap: break-word;
  width: 300px;
  display: flex;
  gap: 1rem;
  font-weight: bold;
  font-size: 1.25rem;
}