.mobile-toggle {
  background: transparent;
  border: none;
  display: flex;
  width: 2.5rem;
  height: 80%;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
  padding: 0;
  margin-right: 1rem;
}

.mobile-toggle__bar {
  /* width: 2.5rem; */
  width: 2rem;
  height: 4px;
  /* height: 2.5px; */
  background: white;
}

.menuButton {
  /* display: none; */
  margin-left: 1rem;
  color: white;
  cursor: pointer;
}

@media (min-width: 768px) {
    .mobile-toggle {
        /* display: none; */
        display: flex;
    }
}