
.shareButtonsLinkContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  padding-bottom: 2rem;
  padding-right: 0.5rem;
}

.shareButtonsLinkCopyResult {
  text-align: right;
  padding-bottom: 0.5rem;
}

.shareButtonsLinkCopyButton {
  cursor: pointer;
  font-size: 1.5rem;
}

.shareButtonsContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}