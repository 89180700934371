body.light-mode {
  background-color: #fff;
  background-color: ghostwhite;
  background-color: rgb(250, 250, 250);
  --background-color: #fff;
  --background-color: ghostwhite;
  --background-color: rgb(250, 250, 250);
  color: #333;
  --color:#333;
  --button-color: #3b0062;
  --input-background: #fff;
  --textTalk-textBackground: whitesmoke;
  --textTalk-textBackground: rgb(240, 240, 240);
  --textTalk-textContainerBackground: whitesmoke;
  --textTalk-textContainerBackground: rgb(245, 245, 245);
  --recentHotPosts-background: rgb(240, 240, 240);
  --white-black: #ffff;
  --black-white: #000;
  --groupTextColor: #000;
  transition: background-color 0.3s ease;
}
body.dark-mode {
  background-color: #1a1919;
  --background-color: #1a1919;
  /* color: #999;
  --color: #999;
  --button-color: #999; */
  color: rgb(190, 190, 190);
  --color: rgb(190, 190, 190);
  --button-color: rgb(190, 190, 190);
  --input-background: rgb(190, 190, 190);
  --input-background: rgb(44, 44, 44);
  --textTalk-textBackground: rgb(53, 53, 53);
  --textTalk-textContainerBackground: rgb(34, 34, 34);
  --recentHotPosts-background: rgb(53, 53, 53);
  --white-black: #000;
  --black-white: white;
  --groupTextColor: ghostwhite;
  transition: background-color 0.3s ease;
}

/* :root {
  --main-bg-color: #1a1919;
} */