
.infoButton {
  position: fixed;
  /* top: 50px; */
  top: 60px;
  right: 5%;
  right: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
}

@media (min-width: 768px) {
  .infoButton {
    /* left: 33rem; */
    /* right: 30px; */
    right: 60px;
    margin-right:300px;
  }
}