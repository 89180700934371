.notifyButton {
  /* position: fixed;
  top: 50px;
  right: 5%; */
  font-size: 1.5rem;
  text-align: right;
  font-weight: bold;
  margin-right: 5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  position: fixed;
  top: 80px;
  right: 2rem;
}

@media (min-width: 768px) {
  .notifyButton {
    /* position: fixed; */
    /* top: 50px; */
    margin-right: 100px;
    /* left: 31rem; */
    margin-right: calc(330px + 2rem);
  }
}