.listElement {
  margin-top: 1rem;
  /* cursor: pointer */
}

.listElementContent {
  font-weight: bolder;
  margin-left: 1rem;
}

.smallButtons {
  /* text-align: right; */
  font-size: 0.75rem;
  font-weight: bolder;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  display: flex;
  overflow: auto;
  gap: 0.25rem;
}

.groupFavoriteNameRow {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.groupFavoriteImage {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 2px;
}
