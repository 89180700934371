.single-post {
  width: 90%;
  margin: auto;
  text-align: center;
  /* color: #3b0062; */
  color: #999;
  color: var(--color);
  word-break: break-word;
}

.single-post h2 {
  font-size: 1rem;
  /* color: #464646; */
  color: #707070;
  padding-bottom: 1rem;
  /* border-bottom: 2px solid #464646; */
  cursor: pointer;
}

.single-post__image {
  height: 20rem;
  width: 20rem;
  margin: 1rem auto;
  cursor: pointer;
}

.single-post__editButton {
  /* text-align: right; */
  padding: 3rem 0;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.single-post__AuthorElement {
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.single-post__AuthorImageContainer {
  width: 3rem;
  text-align: center;
  margin-right: 1rem;
}

.single-post__AuthorImageElement {
  max-width: 100%;
  max-height: 100%;
}

.single-post__FavoriteUserTitle {
  /* margin: 2rem 1rem; */
  padding: 2rem 0;
  /* margin-bottom: 1rem;
  margin */
  /* margin-left: 1rem; */
}

.single-post__FavoriteButton {
  display: flex;
  justify-content: flex-start;
}

.single-post__content {
  text-align: left;
  white-space: pre-line;
}

.single-post__FullImageContainer {
  /* width: 100%;
  height: 100%; */

  margin: auto;
  /* width: 95%; */
  max-width: 1400px;
  height: 95vh;
  /* line-height: 115px; */
  text-align: center;
  /* border: 1px solid red; */

}

.single-post__FullImageMoveUp {
  font-size:2rem; 
  position:fixed; 
  bottom: 20%;
  right: 1px; 
  z-index: 300; 
  color: white; 
  opacity: 0.75; 
  cursor: pointer;
}

.single-post__FullImageMoveDown {
  font-size:2rem; 
  position:fixed; 
  bottom: 20%;
  left: 1px; 
  z-index: 300; 
  color: white; 
  opacity: 0.75; 
  cursor: pointer;
}

.single-post__FullImageNumber { 
  position:fixed; 
  bottom: 1px;
  left: 40%; 
  z-index: 300; 
  color: white; 
  opacity: 0.75;
}

.single-post__FullImageBackButton { 
  text-decoration:'underline';
  cursor:'pointer';
}


img {
  max-width: 100%;
  max-height: 100%;
}

.single-post-notcenter {
  width: 90%;
  margin: auto;
  /* text-align: center; */
  /* color: #3b0062; */
  color: #999;
  color: var(--color);
}

.single-post__shareElementTitle {
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer
}

.single-post__shareElementContainer {
  display: flex;
  align-items: center; 
  margin-bottom: 1rem;
}

.single-post__shareElement {
  margin-right: 1rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.single-post__Images {
  /* display: flex;
  justify-content: center; */
  width: 100%;
  /* maxWidth: '960px',  */
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 100%;
  gap: 0.5rem;
}

.single-post__Images img {
  height: auto; 
  /* margin-right: 2.5px;  */
  cursor: pointer;
}

.single-post__Video {
  /* height: 100%;
  width: 100%; 
  max-width: 40rem; */
  max-width: 100%;
  max-height: 80vh;
}

.single__post__adBar {
  /* padding-top: 2rem; */
  padding-bottom: 2rem;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.changeStyleButton {
  cursor: pointer;
  font-size: 2rem;
  /* font-weight: bold; */
  text-align: right;
}


@media (min-width: 768px) {
  .single-post {
    width: 40rem;
  }

  .single-post-notcenter {
    width: 40rem;
  }

  .single-post__Images {
    grid-template-columns: 1fr 1fr;
  }

  .single-post__Images img {
    height: 20rem; 
    width: 100%;
    object-fit: cover;
    /* margin-right: 2.5px;  */
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
  }

}

/* @media (min-width: 640px) {
  .single-post__Images {
    grid-template-columns: 1fr 1fr;
  }
} */