.kewordListItem {
  /* display:"flex";
  justify-content:"flex-start"; */
  margin-bottom: 1.5rem;
}

.confirmKewordListItem {
  margin-left: 1rem;
}

.editGroupFormContainer {
  width: 100%
}

@media (min-width: 768px) {
  .editGroupFormContainer {
    /* width: 33rem; */
    width: 450px;
  }
}