.listElement {
  margin-top: 1rem;
  /* cursor: pointer */
}

.listElementContent {
  font-weight: bolder;
}

.buttonSmall {
  /* text-align: right; */
  font-size: 0.75rem;
  font-weight: bolder;
  margin-top: 1rem;
}

.userInfoContainer {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.userInfoContent {
  font-weight: bolder;
}

.userImageContainer {
  width: 4.5rem;
  height: 3rem;
  text-align: center;
  /* margin-right: 1rem; */
}

.userImageElement {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  border-radius: 2px;
}

.editResult {
  margin-top: 1rem;
  margin-bottom: 1rem; 
  font-size: 1.25rem;
  font-weight: bolder;
}